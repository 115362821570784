import React, {
  Fragment,
  useState,
  forwardRef,
  useContext,
  useEffect,
} from 'react'
import { UrlServicesContext } from 'components/UrlServicesContext'

// @material-ui/core components
import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from '@material-ui/core/styles'
// core components
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import {
  Search,
  Layers,
  AddCircle,
  Cancel,
  Replay,
  Edit,
} from '@material-ui/icons'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import MaterialTable, { MTableBodyRow } from 'material-table'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import format from 'date-fns/format'
import axios from 'axios'

import NewApplicants from 'views/dialog/NewApplicants'
import EditApplicant from 'views/dialog/EditApplicant'
import ViewUser from 'views/dialog/ViewUser'
import DeleteUser from 'views/dialog/DeleteUser'
import ReactivateUser from 'views/dialog/ReactivateUser'

import { cardBodyStyle } from 'assets/jss/material-dashboard-react/components/cardBodyStyle'

const useStyles = makeStyles(cardBodyStyle)

const useStyles2 = makeStyles({
  tableRow: { '&:hover': { backgroundColor: 'rgb(237, 247, 237)!important' } },
})

const theme = createTheme({
  overrides: {
    MuiIconButton: {
      root: {
        padding: 4,
      },
    },
  },
})

export default function Applicants() {
  const classes = useStyles()
  const tableRef = React.createRef()
  const classes2 = useStyles2()

  const [openNew, setOpenNew] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [openReactivate, setOpenReactivate] = useState(false)
  const [idRow, setIdRow] = useState('')
  const [openViewUser, setopenViewUser] = useState(false)
  const [Rows, setRows] = useState([])
  const [loaderRows, setLoaderRows] = useState(true)

  let keyAuthorization = localStorage.getItem('Session')

  const { urlServices, keyAplication } = useContext(UrlServicesContext)

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  }

  const [state] = useState({
    columns: [
      {
        title: 'Nombre',
        field: 'name',
        render: (rowData) => {
          return (
            <b style={{ fontSize: 12 }}>
              {rowData.lastName + ' ' + rowData.name}
            </b>
          )
        },
        width: '50%',
        cellStyle: {
          whiteSpace: 'nowrap',
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 14,
        },
      },
      {
        title: 'Documento',
        field: 'documentId',
        render: (rowData) => {
          return <span style={{ fontSize: 12 }}>{rowData.documentId}</span>
        },
        width: '30%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 14,
          textAlign: 'center',
        },
      },
      {
        title: 'Correo',
        field: 'email',
        render: (rowData) => {
          return (
            <Fragment>
              <span style={{ fontSize: 12 }}>
                {rowData.email !== '' ? rowData.email : 'SIN REGISTRAR'}
              </span>
            </Fragment>
          )
        },
        width: '30%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 14,
          textAlign: 'center',
        },
      },
      {
        title: 'Registrado',
        field: 'createdAt',
        render: (rowData) => {
          return (
            <span style={{ fontSize: 12 }}>
              {format(new Date(rowData.createdAt), 'dd-MM-yyyy')}
            </span>
          )
        },
        width: '20%',
        cellStyle: {
          // whiteSpace: "nowrap",
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 14,
          textAlign: 'center',
        },
      },
      {
        title: 'Estado',
        field: 'status',
        sorting: false,
        render: (rowData) => {
          const text =
            rowData.status === 1
              ? 'ACTIVO'
              : rowData.status === 99
              ? 'PENDIENTE'
              : 'INACTIVO'
          const color =
            rowData.status === 1
              ? 'green'
              : rowData.status === 99
              ? 'orange'
              : 'red'
          return <b style={{ color: color, fontSize: 10 }}>{text}</b>
        },
        width: '1%',
        cellStyle: {
          // whiteSpace: "nowrap",
          textAlign: 'CENTER',
          border: '1px solid rgb(241, 241, 241)',
          fontSize: 12,
        },
      },
    ],
  })

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    RowsDataFull()
  }, [])

  const RowsDataFull = async () => {
    try {
      const response = await axios.get(
        `${urlServices}users/account/${localStorage.getItem(
          'account_id'
        )}/candidates`,
        {
          headers: {
            application: keyAplication,
            Authorization: 'Bearer ' + keyAuthorization,
          },
        }
      )

      if (response.status === 200) {
        setRows(response.data)
        setLoaderRows(false)
      }
    } catch (error) {}
  }

  const RefreshTable = () => {
    RowsDataFull()
    tableRef.current && tableRef.current.onQueryChange()
  }

  const handleCloseNew = () => {
    setOpenNew(false)
  }

  const handleCloseEdit = () => {
    setOpenEdit(false)
  }

  const handleCloseView = () => {
    setopenViewUser(false)
  }

  const handleCloseDelete = () => {
    setOpenDelete(false)
  }

  const handleCloseReactivate = () => {
    setOpenReactivate(false)
  }

  let url = ''

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <div className={classes.cardTitleWhite}>
              <Layers className={classes.iconWhite} /> Listado de candidatos
            </div>
          </CardHeader>
          <CardBody>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Button
                  className={classes.buttonRight}
                  onClick={(event) => {
                    setOpenNew(true)
                  }}
                  disabled={loaderRows}
                  startIcon={<AddCircle />}
                >
                  Agregar candidato
                </Button>
              </Grid>
            </Grid>
            <ThemeProvider theme={theme}>
              <MaterialTable
                title=""
                tableRef={tableRef}
                columns={state.columns}
                style={{
                  boxShadow: 'unset',
                  border: '1px solid rgb(241, 241, 241)',
                }}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    if (query.search === '') {
                      url = `${urlServices}users/account/${localStorage.getItem(
                        'account_id'
                      )}/candidates?limit=${query.pageSize}&page=${
                        query.page + 1
                      }`
                    } else {
                      url = `${urlServices}users/account/${localStorage.getItem(
                        'account_id'
                      )}/candidates`
                    }
                    fetch(url, {
                      method: 'get',
                      headers: {
                        application: keyAplication,
                        Authorization: 'Bearer ' + keyAuthorization,
                      },
                    })
                      .then((response) => response.json())
                      .then((result) => {
                        resolve({
                          //data: result.data,
                          data:
                            result.data !== undefined
                              ? result.data.filter(function (obj) {
                                  return Object.keys(obj).some(function (key) {
                                    return obj[key]
                                      ? obj[key]
                                          .toString()
                                          .toLowerCase()
                                          .includes(query.search)
                                      : false
                                  })
                                })
                              : [],
                          page: result.page !== undefined ? result.page - 1 : 0,
                          totalCount:
                            result.total !== undefined ? result.total : 0,
                        })
                      })
                  })
                }
                localization={{
                  pagination: {
                    labelRowsSelect: 'Filas',
                    labelDisplayedRows: ' {from}-{to} de {count}',
                    firstTooltip: 'Primera página',
                    previousTooltip: 'Previo',
                    nextTooltip: 'Siguiente',
                    lastTooltip: 'Ultima página',
                  },
                  toolbar: {
                    // nRowsSelected: '{0} row(s) selected',
                    searchTooltip: 'Filtrar',
                    searchPlaceholder: 'Buscar',
                  },
                  header: {
                    actions: ' --- ',
                  },
                  body: {
                    emptyDataSourceMessage: 'No hay datos.',
                    filterRow: {
                      filterTooltip: 'Filtro',
                    },
                  },
                }}
                actions={[
                  {
                    icon: 'search',
                    tooltip: 'Ver',
                    onClick: (event, rowData) => {
                      setopenViewUser(true)
                      setIdRow(rowData)
                    },
                  },
                  // {
                  //   icon: 'edit',
                  //   tooltip: 'Editar',
                  //   onClick: (event, rowData) => {
                  //     setOpenEdit(true)
                  //     setIdRow(rowData)
                  //   },
                  // },
                  (rowData1) => ({
                    icon: () => <Edit />,
                    tooltip: 'Editar',
                    onClick: (event, rowData) => {
                      setOpenEdit(true)
                      setIdRow(rowData)
                    },
                    hidden: rowData1.status === 0 ? true : false,
                  }),
                  (rowData1) => ({
                    icon: () => <Cancel />,
                    tooltip: 'Deshabilitar',
                    onClick: (event, rowData) => {
                      setOpenDelete(true)
                      setIdRow(rowData)
                    },
                    hidden: rowData1.status === 0 ? true : false,
                  }),
                  (rowData1) => ({
                    icon: () => <Replay />,
                    tooltip: 'Reactivar',
                    onClick: (event, rowData) => {
                      setOpenReactivate(true)
                      setIdRow(rowData)
                    },
                    hidden: rowData1.status === 0 ? false : true,
                  }),
                  {
                    icon: 'refresh',
                    tooltip: 'Refresh Data',
                    isFreeAction: true,
                    onClick: () =>
                      tableRef.current && tableRef.current.onQueryChange(),
                  },
                ]}
                icons={tableIcons}
                options={{
                  sorting: true,
                  search: true,
                  padding: 'default',
                  filtering: false,
                  actionsColumnIndex: -1,
                  pageSize: 10,
                  headerStyle: {
                    background: '#F2F2F2',
                    padding: '1%',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  },
                  cellStyle: { border: '1px solid #DDDDDD' },
                }}
                components={{
                  Row: (props) => (
                    <MTableBodyRow className={classes2.tableRow} {...props} />
                  ),
                }}
              />
            </ThemeProvider>
          </CardBody>
        </Card>
      </GridItem>
      {openNew && (
        <NewApplicants
          open={openNew}
          exit={handleCloseNew}
          rows={Rows}
          callBackRefresh={RefreshTable}
        />
      )}
      {openEdit && (
        <EditApplicant
          open={openEdit}
          exit={handleCloseEdit}
          id={idRow}
          callBackRefresh={RefreshTable}
        />
      )}

      {openViewUser ? (
        <ViewUser open={openViewUser} exit={handleCloseView} id={idRow} />
      ) : (
        ''
      )}

      {openDelete && (
        <DeleteUser
          open={openDelete}
          exit={handleCloseDelete}
          id={idRow}
          callBackRefresh={RefreshTable}
        />
      )}

      {openReactivate && (
        <ReactivateUser
          open={openReactivate}
          exit={handleCloseReactivate}
          id={idRow}
          callBackRefresh={RefreshTable}
        />
      )}
    </GridContainer>
  )
}
